import { useBoolean } from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useState } from 'react';

import { GSAppContext } from '@/components/context/GSAppContext';
import { SignInMode } from '@/components/context/useSignInContext';

import useRedirectIf from './useRedirectIf';

type UseOnSignedOutOptions = {
  skip?: boolean;
  alwayRedirect?: boolean;
  signInMode?: SignInMode;
};

const useOnSignedOut = (
  path: string,
  { skip, alwayRedirect, signInMode }: UseOnSignedOutOptions = {},
) => {
  const {
    user: { initialized, loading, user },
    signIn: { openSignInModal },
  } = useContext(GSAppContext);
  const [didFailLogin, setDidFailLogin] = useState(false);
  const doRedirect = didFailLogin || alwayRedirect;
  // @ts-expect-error TODO Couldn't get to some of these in
  // https://plslogistics.atlassian.net/browse/GS-736
  const pushing = useRedirectIf(path, doRedirect);
  const [
    signInPending,
    { on: setSignInPending, off: clearSignInPending },
  ] = useBoolean(false);

  const onClose = useCallback(
    success => {
      setDidFailLogin(!success);
      clearSignInPending();
    },
    [clearSignInPending],
  );

  useEffect(() => {
    if (
      !skip &&
      initialized &&
      !loading &&
      !user &&
      !pushing &&
      !doRedirect &&
      !signInPending
    ) {
      openSignInModal(signInMode || 'signup', onClose);
      setSignInPending();
    }
  }, [
    initialized,
    loading,
    user,
    pushing,
    doRedirect,
    onClose,
    openSignInModal,
    signInMode,
    skip,
    setSignInPending,
    signInPending,
  ]);
  return pushing || signInPending;
};

export default useOnSignedOut;
