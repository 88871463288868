import { Input, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { DownloadIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { range } from 'lodash';
import { useRouter } from 'next/router';
import React, { useState, useContext, useCallback } from 'react';

import { appBgColor, mainGreyText } from '@/styles/Colors';
import { Booking, ShipmentType } from '@/utils/types';

import { GSAppContext } from '../context/GSAppContext';
import { SortHeader, useSortHeaders } from '../SortHeader';

import BookingCard, { LoadingCard } from './BookingCard';
import UpchargeBanner from './upcharge/Banner';

const NEAREST_MINUTE = 'YYYY-MM-DD hh:mm';

export const StatusSorter = {
  label: 'Status',
  toKey: ({ loadStatus }: Booking) => loadStatus,
};
export const BoLSorter = {
  label: 'BOL',
  toKey: ({ id }: Booking) => id,
};
export const TypeSorter = {
  label: 'Type',
  toKey: ({ shipmentType }: { shipmentType: ShipmentType }) => shipmentType,
};
export const CarrierSorter = {
  label: 'Carrier',
  toKey: ({ carrier }: Booking) => carrier?.name || '',
};
export const PickupSorter = {
  label: 'Pickup',
  toKey: ({ origin: { date } }: Booking) => dayjs(date).format(NEAREST_MINUTE),
};
export const DeliverySorter = {
  label: 'Delivery',
  toKey: ({ destination: { date } }: Booking) =>
    dayjs(date).format(NEAREST_MINUTE),
};
export const TrackingNumberSorter = {
  label: 'Tracking #',
  toKey: ({ trackingNumber }: Booking) => trackingNumber || '',
};

const BookingTable = () => {
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState('');
  const {
    broker: { useFetchBookings },
  } = useContext(GSAppContext);
  const { loading: bookingsLoading, bookings } = useFetchBookings();
  const { onSort, sortFunction } = useSortHeaders();
  const bolFilterFunction = useCallback(
    ({ id }: Booking) =>
      !searchQuery || `${id}`.toLowerCase().includes(searchQuery.toLowerCase()),
    [searchQuery],
  );

  const filteredBookings = bookings.filter(bolFilterFunction);
  filteredBookings.sort(sortFunction);

  return (
    <div className="flex-1 flex flex-col py-4">
      <div className="text-2xl text-center">My Order History</div>

      <UpchargeBanner />

      <div className="mt-4 px-4">
        <Input
          type="text"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search by BOL"
          style={{ backgroundColor: 'white' }}
        />
      </div>

      <Table>
        <Thead>
          <Tr>
            <Th
              position="sticky"
              style={{
                padding: 0,
                margin: 0,
                top: 0,
                backgroundColor: appBgColor,
              }}>
              <div className="flex mt-4 mb-2 px-6">
                <SortHeader
                  className="flex-1 items-center"
                  sort={StatusSorter}
                  onSort={onSort}
                />
                <SortHeader
                  className="flex-1 items-center"
                  sort={BoLSorter}
                  onSort={onSort}
                />
                <SortHeader
                  className="flex-1 items-center md:w-6"
                  sort={TypeSorter}
                  onSort={onSort}>
                  <div className="w-12 justify-end hidden md:flex">
                    <DownloadIcon
                      className="h-6 w-6"
                      style={{ color: mainGreyText }}
                    />
                  </div>
                </SortHeader>
                <div className="w-12 flex justify-end md:hidden">
                  <DownloadIcon
                    className="h-6 w-6"
                    style={{ color: mainGreyText }}
                  />
                </div>
                <SortHeader
                  className="hidden md:flex flex-1 items-center"
                  sort={CarrierSorter}
                  onSort={onSort}
                />
                <SortHeader
                  className="hidden md:flex flex-1 items-center"
                  sort={TrackingNumberSorter}
                  onSort={onSort}
                />
                <SortHeader
                  className="hidden md:flex flex-1 items-center"
                  sort={PickupSorter}
                  onSort={onSort}
                />
                <SortHeader
                  className="hidden md:flex flex-1 items-center"
                  sort={DeliverySorter}
                  onSort={onSort}
                />
              </div>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          <Tr>
            <Td style={{ margin: 0, padding: 0 }}>
              <div className="mt-4 px-4">
                {!bookingsLoading && filteredBookings.length === 0 && (
                  <div className="text-2xl flex justify-center mb-4">
                    No bookings found
                  </div>
                )}
                {bookingsLoading
                  ? range(6).map(i => <LoadingCard key={i} />)
                  : filteredBookings.map(b => (
                      <BookingCard
                        key={b.id}
                        booking={b}
                        onSelect={() =>
                          router.push(`/load/${b.shipmentType}/${b.id}`)
                        }
                      />
                    ))}
              </div>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
};
export default BookingTable;
