import { Tooltip } from '@chakra-ui/react';
import {
  CurrencyDollarIcon,
  DocumentDownloadIcon,
} from '@heroicons/react/outline';
import cx from 'classnames';
import dayjs from 'dayjs';
import { capitalize, range } from 'lodash';
import { useContext } from 'react';

import { convertRawLoadStatus } from '@/backend/BackendTypes';
import getBalanceTransactionInvoiceUrl from '@/backend/graphql/address/getBalanceTransactionInvoiceUrl';
import getBOLPdf from '@/backend/graphql/booking/getBOLPdf';
import { genImgUrl } from '@/components/booking/CarrierLogo';
import LabelText from '@/components/booking/LabelText';
import { formLabelGrey, goShipGreen } from '@/styles/Colors';
import { onKeyDownIsEnter } from '@/utils/onKeyDownIsEnter';
import { Booking } from '@/utils/types';

import { GSAppContext } from '../context/GSAppContext';

type BookingCardProps = {
  booking: Booking;
  onSelect: () => void;
};

const BookingCardCarrierLogo = ({
  booking,
  className,
}: {
  booking: Booking;
  className?: string;
}) =>
  booking && booking.carrier?.name ? (
    <div className={cx('h-1/3 md:h-1/2 w-24 overflow-hidden mt-2', className)}>
      <img
        className="w-auto"
        alt={booking.carrier.name}
        src={genImgUrl(booking.scacCode)}
      />
    </div>
  ) : (
    <div className={cx('h-1/3 md:h-1/2 w-24 overflow-hidden mt-2', className)}>
      <img className="w-auto" alt="ftl" src="/ftl.png" />
    </div>
  );

export default function BookingCard({ booking, onSelect }: BookingCardProps) {
  const {
    broker: { useDownloadDocument },
  } = useContext(GSAppContext);
  const { downloadDocument } = useDownloadDocument();
  const downloadBoL = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const { bolPdf } = await getBOLPdf(booking.id);
    if (bolPdf.length) {
      window.open(bolPdf, '_blank');
    } else {
      downloadDocument(booking, 'BOL');
    }
  };

  const getInvoiceLink = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (booking.url) {
      window.open(booking.url);
    } else {
      const { url: balanceInvoiceUrl } = await getBalanceTransactionInvoiceUrl(
        booking.txnId || '',
      );
      window.open(balanceInvoiceUrl);
    }
  };

  const loadStatusDisplay = capitalize(
    // @ts-expect-error TODO Couldn't get to some of these in
    // https://plslogistics.atlassian.net/browse/GS-736
    convertRawLoadStatus(booking.loadStatus),
  );

  return (
    <div
      tabIndex={0}
      role="button"
      className="bg-white rounded shadow mb-4 flex p-2 pb-0 text-sm flex-wrap md:text-base hover:shadow-lg transition-all"
      onKeyDown={onKeyDownIsEnter(onSelect)}
      onClick={() => onSelect()}>
      <div className="flex flex-1">
        <div className="flex-1 flex flex-col mb-2">
          <div>
            <LabelText className="md:hidden">Status</LabelText>
            <div className="font-semibold md:font-normal">
              {loadStatusDisplay}
            </div>
          </div>
          <div className="hidden md:block md:h-16">
            <BookingCardCarrierLogo booking={booking} />
          </div>
        </div>
        <div className="flex-1">
          <LabelText className="md:hidden">BOL #</LabelText>
          <div className="font-semibold md:font-normal">{booking.id}</div>
        </div>
        <div className="flex-1 flex justify-between">
          <div>
            <LabelText className="md:hidden">Type</LabelText>
            <div className="uppercase font-semibold md:font-normal">
              {booking.shipmentType}
            </div>
          </div>
          <div className="flex-0 hidden md:block mr-2">
            <Tooltip label="Download BOL" shouldWrapChildren>
              <DocumentDownloadIcon
                className="h-6 w-6 cursor-pointer"
                style={{ color: goShipGreen }}
                onClick={downloadBoL}
              />
            </Tooltip>
          </div>
          {booking?.txnId || booking?.url ? (
            <div className="flex-0 hidden md:block mr-2">
              <Tooltip label="Invoice Link" shouldWrapChildren>
                <CurrencyDollarIcon
                  className="h-6 w-6 cursor-pointer"
                  style={{ color: goShipGreen }}
                  onClick={getInvoiceLink}
                />
              </Tooltip>
            </div>
          ) : (
            <div className="flex-0 hidden md:block mr-2">
              <Tooltip label="No Invoice" shouldWrapChildren>
                <CurrencyDollarIcon
                  className="h-6 w-6 cursor-pointer"
                  style={{ color: formLabelGrey }}
                />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="flex-0 md:hidden">
          <DocumentDownloadIcon
            className="h-6 w-6 cursor-pointer"
            style={{ color: goShipGreen }}
            onClick={downloadBoL}
          />
          {booking?.txnId || booking?.url ? (
            <CurrencyDollarIcon
              className="h-6 w-6 cursor-pointer"
              style={{ color: goShipGreen }}
              onClick={getInvoiceLink}
            />
          ) : (
            <div className="flex-0 hidden md:block mr-2">
              <Tooltip label="No Invoice" shouldWrapChildren>
                <CurrencyDollarIcon
                  className="h-6 w-6 cursor-pointer"
                  style={{ color: formLabelGrey }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-1">
        <div className="flex-1 h-24">
          {booking.shipmentType === 'LTL' ? (
            <div className="h-full flex flex-col">
              <LabelText className="md:hidden">Carrier</LabelText>
              <div className="font-semibold w-24 truncate md:whitespace-normal md:font-normal">
                <Tooltip label={booking.carrierPhoneNumber} shouldWrapChildren>
                  {booking.carrier?.name}
                </Tooltip>
              </div>
              <BookingCardCarrierLogo booking={booking} className="md:hidden" />
            </div>
          ) : (
            <div>
              <div className="h-full flex flex-col">
                <LabelText className="md:hidden">Carrier</LabelText>
                <div className="font-semibold w-24 truncate md:whitespace-normal md:font-normal">
                  <Tooltip
                    label={booking.carrierPhoneNumber}
                    shouldWrapChildren>
                    N/A
                  </Tooltip>
                </div>
                <BookingCardCarrierLogo
                  booking={booking}
                  className="md:hidden"
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex-1 h-24">
          <LabelText className="md:hidden">Tracking #</LabelText>
          <div className="w-32">{booking.trackingNumber || ''}</div>
        </div>
        <div className="flex-1 h-24">
          <LabelText className="md:hidden">Pickup</LabelText>
          <div className="w-28">
            {booking.origin.address.city}, {booking.origin.address.state}
          </div>
          <div>
            <div className="font-semibold">
              {dayjs(booking.origin.date).format('MMM D')}
            </div>
          </div>
        </div>
        <div className="flex-1 h-24">
          <LabelText className="md:hidden">Delivery</LabelText>
          <div className="w-28">
            {booking.destination.address.city},{' '}
            {booking.destination.address.state}
          </div>
          <div>
            <div className="font-semibold">
              {dayjs(booking.destination.date).format('MMM D')}
            </div>
          </div>
        </div>
        <div className="flex-0 w-6 md:hidden"></div>
      </div>
    </div>
  );
}

export const LoadingCard = () => (
  <div className="bg-white rounded shadow mb-4 p-4 h-36 md:h-24">
    <div className="animate-pulse flex h-full">
      {range(3).map(i => (
        <div key={i} className="flex-1 flex flex-col justify-around">
          <div>
            <div className="h-4 mb-1 w-3/4 bg-gray-200 rounded" />
            <div className="h-2 mb-1 w-1/2 bg-gray-200 rounded" />
          </div>
          <div>
            <div className="h-4 mb-1 w-3/4 bg-gray-200 rounded" />
            <div className="h-2 mb-1 w-1/2 bg-gray-200 rounded" />
          </div>
          <div className="md:hidden">
            <div className="h-4 mb-1 w-3/4 bg-gray-200 rounded" />
            <div className="h-2 mb-1 w-1/2 bg-gray-200 rounded" />
          </div>
        </div>
      ))}
    </div>
  </div>
);
