import { Th } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import cx from 'classnames';
import React, { FC, useCallback, useState } from 'react';

import { onKeyDownIsEnter } from '@/utils/onKeyDownIsEnter';
import { Booking } from '@/utils/types';

export const StickyTh: FC = ({ children }) => (
  <Th position="sticky" className="bg-gray-200 top-0">
    {children}
  </Th>
);

export type Sorter = {
  label: string;
  toKey: (b: Booking) => string;
};

export const SortHeader: FC<{
  sort: Sorter;
  onSort: (s: Sorter, d: boolean) => void;
  className?: string;
  children?: React.ReactNode;
}> = ({ children, className, sort, onSort }) => {
  const [direction, setDirection] = useState(false);
  const onClick = () => {
    onSort(sort, direction);
    setDirection(d => !d);
  };
  const onKeyDown = onKeyDownIsEnter(onClick);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={onKeyDown}
      onClick={onClick}
      className={cx('flex flex-row', className)}>
      <div className="mr-2">{sort.label}</div>
      {direction ? (
        <ChevronDownIcon className="w-3" />
      ) : (
        <ChevronUpIcon className="w-3" />
      )}
      {children}
    </div>
  );
};

export const useSortHeaders = () => {
  const [sorters, setSorters] = useState<[Sorter, boolean][]>([]);
  const sortFunction = useCallback(
    (b1: Booking, b2: Booking) =>
      sorters.reduce((acc, [{ toKey }, dir]) => {
        if (acc === 0) {
          const k1 = toKey(b1);
          const k2 = toKey(b2);
          if (k1 === k2) return 0;
          return (k1 > k2 ? -1 : 1) * (dir ? -1 : 1);
        }
        return -1;
      }, 0),
    [sorters],
  );
  const onSort = useCallback((s: Sorter, direction) => {
    setSorters([[s, direction]]);
  }, []);

  return { onSort, sortFunction };
};
