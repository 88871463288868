import { useBoolean } from '@chakra-ui/react';
import Head from 'next/head';

import BookingTable from '@/components/dashboard/BookingsTable';
import DashboardMenu from '@/components/dashboard/DashboardMenu';
import useOnSignedOut from '@/utils/hooks/useOnSignedOut';

export default function Dashboard() {
  useOnSignedOut('/booking/request-quote');
  const [isMenuHidden, { toggle: toggleIsMenuHidden }] = useBoolean(true);

  return (
    <>
      <Head>
        <title>GoShip - Dashboard</title>
      </Head>

      <div className="md:flex">
        <DashboardMenu
          isHidden={isMenuHidden}
          toggleIsHidden={toggleIsMenuHidden}
        />
        <BookingTable />
      </div>
    </>
  );
}
