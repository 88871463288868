import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import { GSAppContext } from '@/components/context/GSAppContext';
import { useIsFixtureInitialize } from '@/components/LoadFixtureData';

const useRedirectIf = (path: string, doRedirect: boolean) => {
  const router = useRouter();
  const [pushing, setPushing] = useState(false);
  const {
    user: { initialized: userInitialized },
  } = useContext(GSAppContext);
  const fixtureInitialized = useIsFixtureInitialize();
  useEffect(() => {
    if (
      path &&
      userInitialized &&
      fixtureInitialized &&
      doRedirect &&
      !pushing
    ) {
      setPushing(true);
      router.push(path);
    }
  }, [doRedirect, fixtureInitialized, path, pushing, router, userInitialized]);
  return pushing;
};
export default useRedirectIf;
