import { useToast } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import cx from 'classnames';
import { noop } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import { GSAppContext } from '@/components/context/GSAppContext';
import { formErrorText, goShipGreen } from '@/styles/Colors';
import useSignOut from '@/utils/hooks/useSignOut';
import { onKeyDownIsEnter } from '@/utils/onKeyDownIsEnter';

import {
  BookingFlowContext,
  BookingFlowContextValues,
} from '../context/BookingFlowProvider';

// @ts-expect-error TODO Couldn't get to some of these in
// https://plslogistics.atlassian.net/browse/GS-736
const ActiveAwareLink = ({ href, children }) => {
  const router = useRouter();
  const hasWindow = typeof window !== 'undefined';
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (hasWindow) {
      setIsActive(router.pathname === href);
    }
  }, [hasWindow, router.pathname, href]);

  return (
    <Link href={href}>
      <span
        className={cx('cursor-pointer text-xl', { 'font-bold': isActive })}
        style={{ color: isActive ? goShipGreen : 'inherit' }}>
        {children}
      </span>
    </Link>
  );
};

export default function DashboardMenu({
  isHidden = false,
  toggleIsHidden,
}: {
  isHidden: boolean;
  toggleIsHidden: () => void;
}) {
  const {
    user: { useGetUserStatus, resendVerificationEmail, vendor },
  } = useContext(GSAppContext);
  const router = useRouter();
  const userActive = useGetUserStatus();
  const { dispatch } = useContext<BookingFlowContextValues>(BookingFlowContext);

  const callResendVerificationEmail = async () => {
    const emailSent = await resendVerificationEmail();
    if (emailSent) {
      toast({
        title: 'Email Sent.',
        description: "We've sent a verification email. Please check your email",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const performSignout = useSignOut();
  const toast = useToast();
  return (
    <div className="flex flex-col w-48 h-2/3 pt-2 md:pt-16">
      <div
        className={cx(
          [
            'fixed',
            'md:static',
            'z-10',
            'left-0',
            'bg-white',
            'rounded',
            'shadow-lg',
            'md:shadow',
            'transition-all',
            'duration-200',
          ],

          {
            '-left-40': isHidden,
          },
        )}>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={noop}
          className="p-4 text-xl border-b "
          onClick={() => {
            dispatch({
              type: 'reset',
            });
            router.push('/booking/request-quote');
          }}>
          Request Quote
        </div>

        <div className="p-4 border-b">
          <ActiveAwareLink href="/">View Orders</ActiveAwareLink>
        </div>
        {vendor && (
          <div className="p-4 border-b">
            <ActiveAwareLink href="/booking/vendor-orders">
              Vendor Orders
            </ActiveAwareLink>
          </div>
        )}
        <div className="p-4 border-b">
          <ActiveAwareLink href="/account">Account</ActiveAwareLink>
        </div>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={onKeyDownIsEnter(performSignout)}
          className="p-4 text-xl border-b md:border-b-0"
          onClick={() => {
            performSignout();
            toggleIsHidden();
            dispatch({
              type: 'reset',
            });
            router.push('/booking/request-quote');
          }}>
          Logout
        </div>
        {!userActive && (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={noop}
            className="p-4 text-xl border-t "
            style={{ color: formErrorText }}
            onClick={() => {
              callResendVerificationEmail();
            }}>
            Verify Email
          </div>
        )}
        <div
          tabIndex={0}
          role="button"
          onKeyDown={onKeyDownIsEnter(toggleIsHidden)}
          className="absolute -right-8 top-0 bg-white rounded-r border w-8 h-12 flex items-center justify-center cursor-pointer"
          onClick={toggleIsHidden}>
          {isHidden ? (
            <ChevronRightIcon
              className="h-6 w-6 text-gray-400"
              style={{ color: userActive ? 'inherit' : formErrorText }}
            />
          ) : (
            <ChevronLeftIcon
              className="h-6 w-6 text-gray-400"
              style={{ color: userActive ? 'inherit' : formErrorText }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
