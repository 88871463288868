import { gql } from '@apollo/client';

import graphqlClient from '@/backend/graphql';

import { GetBalanceTransaction } from './__generated__/GetBalanceTransaction';

const GET_BALANCE_TRANSACTION = gql`
  query GetBalanceTransaction($txnId: String!) {
    getBalanceTransaction(txnId: $txnId) {
      charges {
        url
      }
    }
  }
`;

export default async function getBalanceTransactionInvoiceUrl(txnId: string) {
  const resp = await graphqlClient.query<GetBalanceTransaction>({
    query: GET_BALANCE_TRANSACTION,
    variables: { txnId },
  });

  const url = resp?.data?.getBalanceTransaction?.charges?.[0]?.url;
  return { url };
}
